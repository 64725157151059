<template>
  <div class="w-100" v-if="history.length > 0">
    <div class="d-flex justify-content-between">
      <h4>{{is_favorite ? $t('filters.savedSearchs') : $t('filters.recentSearchs')}} </h4>
      <p class="avenir-medium view-more-button" @click="toggleAllResults" v-if="history.length > 3">{{ !toggle_results ? $t('creator.viewMore') : $t('filters.showLess')}}</p>
    </div>
    <div class="grid-history-filters-search">
      <card-history-search 
        v-for="(i, index) in toggle_results ? history_filtered_pagination : history"
        :key="index"
        :i="i"
        :index="index"
        :loading_service="loading_service"
        @update_history="updateHistory"
        @delete_history="deleteHistory"
        @set_filters="applyFilters"
      >
      </card-history-search>
    </div>
    <pagination-brandme 
      class="mt-2 w-100 d-flex justify-content-center"
      :total_rows="history.length"
      :per_page="per_page"
      :current_page="current_page"
      @update_current_page="updateCurrentPage"
      v-if="toggle_results"
    ></pagination-brandme>
  
  </div>
</template>

<script>
import services_search from '@/services/search';
import { getFormat } from '@/libs/utils/formats';
import { getDatev2 } from '@/libs/utils/times';

export default {
  name: 'historyFiltersSearch',
  components: {
    paginationBrandme: () => import('@/views/components/pagination/PaginationBrandme.vue'),
    cardHistorySearch: () => import('@/views/buscador/filters/components/cardHistorySearch.vue')
  },
  props: {
    is_favorite: {
      type: Boolean,
      default: false
    },
    loading_service: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      getFormat,
      getDatev2,
      history: [],
      all_results: [],
      is_editing_title: false,
      title: '',
      toggle_results: false,
      per_page: 16,
      current_page: 1,
    }
  },
  mounted() {
    this.getHistory()
  },
  computed: {
    history_filtered_pagination () {
      const items = this.history;
      return items.length > 0 ? items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page) : 0;
    },
  },
  methods: {
    updateCurrentPage(new_current_page) {
      this.current_page = new_current_page;
    },
    applyFilters(history) {
      this.$emit('set_filters', history)
    },
    getHistory() {
      services_search.getHistorySearches({only_favorites: this.is_favorite}).then((response) => {
        this.all_results = response.results;
        this.history = response.results.slice(0, 4)
      })
    },
    deleteHistory(search_uuid) {  
      this.all_results = this.all_results.filter(i => i.uuid !== search_uuid)
      this.history = !this.toggle_results ? this.all_results.slice(0, 4) : this.all_results;
    },
    editTitleAction(filters, index) {
      this.title = filters.title ? filters.title : filters.network;

      this.toggleClassesHideInput(index)
    },
    toggleClassesHideInput(index) {
      const container_title = this.$refs[`container_title_${index}`][0]
      const container_input_title = this.$refs[`container_input_title_${index}`][0]

      container_title.classList.toggle('d-flex')
      container_title.classList.toggle('d-none')

      container_input_title.classList.toggle('d-none');
    },
    updateHistory(searcher_uuid, response) {
      this.all_results = this.all_results.map(i => {
        if (i.uuid === searcher_uuid) i = response;
        return i
      })
      this.history = !this.toggle_results ? this.all_results.slice(0, 4) : this.all_results;
    },
    toggleAllResults() {
      this.toggle_results = !this.toggle_results
      if (this.toggle_results) {
        this.history = this.all_results
      } else this.history = this.all_results.slice(0, 4)
    }
  }
}
</script>
<style lang="scss" scoped>
.view-more-button {
  cursor: pointer;
  &:hover {
    color: #0096fe;
  }
}
.grid-history-filters-search {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  @media(max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
<style lang="scss">
.dropdown-options-history-search button {
  padding: 0 !important;
}
</style>